import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { MdDownloadForOffline } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';
import { AiTwotoneDelete } from 'react-icons/ai';
import { BsFillArrowUpRightCircleFill, BsPinAngleFill } from 'react-icons/bs';

import { client, urlFor } from '../client'
import { fetchUser } from '../utils/fetchUser';
import jwt_decode from 'jwt-decode'

const Pin = ({ pin: { postedBy, image, _id, destination, save } }) => {

  const [postHovered, setPostHovered] = useState(false);
  const [savingPost, setSavingPost] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const navigate = useNavigate();
  // const user = jwt_decode(fetchUser())
  const user = localStorage.getItem('user') !== 'undefined' ? jwt_decode(JSON.parse(localStorage.getItem('user'))) : localStorage.clear();
  //console.log(user)
  //console.log(postedBy)

  //const alreadySaved = !!(save?.filter((item) => item?.postedBy?._id === user.sub))?.length
  const alreadySaved = JSON.stringify(save).includes(user?.sub); //my own implementation for better speed
  //console.log(save)

  const savePin = (id) => {
    if (!alreadySaved) {
      setSavingPost(true);

      client
        .patch(id)
        .setIfMissing({ save: [] })
        .insert('after', 'save[-1]', [{
          _key: uuidv4(),
          userId: user?.sub,
          postedBy: {
            _type: 'postedBy',
            _ref: user?.sub,
          },
        }])
        .commit()
        .then(() => {
          // window.location.reload();
          window.alert("Pinned! This is now stored as of your favorites and you can access it in your profile page.")
          setSavingPost(false);
          setIsActive(current => !current);
        });
    }
  };

  const deletePin = (id) => {
    client
      .delete(id)
      .then(() => {
        window.location.reload();
      });
  };

  const navClearDiv = () => {
    navigate(`/pin-detail/${_id}`)
    
    const itemHolder = document.getElementById("itemHolder");
    // Remove all child elements
    while (itemHolder.firstChild) {
      itemHolder.removeChild(itemHolder.firstChild);
    }
    
  }

  return (
    <div className='m-2'>

      <div onMouseEnter={() => setPostHovered(true)}
        onMouseLeave={() => setPostHovered(false)}
        onClick={() => navClearDiv()}
        className=" relative cursor-pointer w-auto hover:shadow-lg rounded-lg overflow-hidden transition-all duration-500 ease-in-out"
      >

        {image && <img className='rounded-lg w-full' alt='user-post' src={urlFor(image).width(250).url()} />}

        {postHovered && (
          <div
            className="absolute top-0 w-full h-full flex flex-col justify-between p-1 pr-2 pt-2 pb-2 z-50"
            style={{ height: '100%' }}
          >
            {/* <div className="flex items-center justify-between">
              <div className="flex gap-2">
                <a
                  href={`${image?.asset?.url}?dl=`}
                  download
                  onClick={(e) => {
                    e.stopPropagation(); //to prevent redirection to PinDetail, since the button lies in front of the image
                  }}
                  className="bg-white w-9 h-9 p-2 rounded-full flex items-center justify-center text-dark text-xl opacity-75 hover:opacity-100 hover:shadow-md outline-none"
                ><MdDownloadForOffline />
                </a>
              </div>
              {alreadySaved ? (
                <button type="button" className="bg-red-500 opacity-70 hover:opacity-100 text-white font-bold px-5 py-1 text-base rounded-3xl hover:shadow-md outline-none">
                  {save.length} Saved
                </button>
              ) : (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    savePin(_id)
                  }}
                  type="button"
                  className="bg-red-500 opacity-70 hover:opacity-100 text-white font-bold px-5 py-1 text-base rounded-3xl hover:shadow-md outline-none"
                >
                  {savingPost ? 'Saving' : 'Save'}
                </button>
              )}
            </div> */}


            <div className=" flex justify-between items-center gap-2 w-full">
              {destination ? (
                <p
                  className="opacity-75 bg-white flex items-center gap-2 text-black text-xs font-bold p-2 pl-4 pr-4 rounded-full opacity-75"
                  rel="noreferrer"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {' '}
                  {destination.slice(0, 15)}...
                </p>
              ) : undefined}
              {
                postedBy?._id === user?.sub && (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      deletePin(_id);
                    }}
                    className="bg-white p-2 rounded-full w-8 h-8 flex items-center justify-center text-dark opacity-75 hover:opacity-100 outline-none"
                  >
                    <AiTwotoneDelete />
                  </button>
                )
              }
            </div>
          </div>
        )}
      </div>
      <div className='flex justify-between items-center'>
        <Link to={`/user-profile/${postedBy?._id}`} className="flex gap-2 mt-2 items-center">
          <img
            className="w-8 h-8 rounded-full object-cover"
            src={postedBy?.image}
            alt="user-profile"
            referrerPolicy="no-referrer"
          />
          {/* <p className="font-semibold capitalize">{postedBy?.userName}</p> */}
        </Link>
        <div className='flex'>
          {alreadySaved ? (
            <button type="button" className="mt-2 bg-green-400 w-9 h-9 p-2 rounded-full flex items-center justify-center text-dark text-xl opacity-75 hover:opacity-100 shadow-md outline-none">
              <BsPinAngleFill />
            </button>
          ) : (
            <button
              onClick={(e) => {
                e.stopPropagation();
                savePin(_id)
              }}
              type="button"
              className={isActive ? "mt-2 bg-green-400 w-9 h-9 p-2 rounded-full flex items-center justify-center text-dark text-xl opacity-75 hover:opacity-100 shadow-md outline-none" : "mt-2 bg-white w-9 h-9 p-2 rounded-full flex items-center justify-center text-dark text-xl opacity-75 hover:opacity-100 shadow-md outline-none"}>
              <BsPinAngleFill />
            </button>
          )}
          <div className="flex gap-2">
            <a
              href={`${image?.asset?.url}?dl=`}
              download
              onClick={(e) => {
                e.stopPropagation(); //to prevent redirection to PinDetail, since the button lies in front of the image
              }}
              className="mt-2 bg-white w-9 h-9 p-2 mx-1 rounded-full flex items-center justify-center text-dark text-xl opacity-75 hover:opacity-100 shadow-md outline-none"
            ><MdDownloadForOffline />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Pin