import React from 'react';
import { useState, useRef } from 'react';
import styled from 'styled-components'

function Newsletter() {


const formRef = useRef(null)
const scriptUrl = "https://script.google.com/macros/s/AKfycbxM6bHD9C1gK050vpiFuWM3tuT1QFf7PWmC1FI2B32gakD2lTrdWndRViqEhT_dnzt2-w/exec"
const [loading, setLoading] = useState(false)

const handleSubmit = (e) => {
  e.preventDefault()
  setLoading(true)

  fetch(scriptUrl, {
    method: 'POST',
    body: new FormData(formRef.current),

  }).then(res => {
    alert("We've gotten your message!")
    setLoading(false)
  })
    .catch(err => console.log(err))
}


  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20">

          {/* CTA box */}
          <div className="relative bg-gray-600 rounded-3xl py-10 px-8 md:py-12 md:px-12 shadow-2xl overflow-hidden" data-aos="zoom-y-out">

            {/* Background illustration */}
            <div className="absolute right-0 bottom-0 pointer-events-none hidden lg:block" aria-hidden="true">
              <svg width="428" height="328" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <radialGradient cx="35.542%" cy="34.553%" fx="35.542%" fy="34.553%" r="96.031%" id="ni-a">
                    <stop stopColor="#DFDFDF" offset="0%" />
                    <stop stopColor="#4C4C4C" offset="44.317%" />
                    <stop stopColor="#333" offset="100%" />
                  </radialGradient>
                </defs>
                <g fill="none" fillRule="evenodd">
                  <g fill="#FFF">
                    <ellipse fillOpacity=".04" cx="185" cy="15.576" rx="16" ry="15.576" />
                    <ellipse fillOpacity=".24" cx="100" cy="68.402" rx="24" ry="23.364" />
                    <ellipse fillOpacity=".12" cx="29" cy="251.231" rx="29" ry="28.231" />
                    <ellipse fillOpacity=".64" cx="29" cy="251.231" rx="8" ry="7.788" />
                    <ellipse fillOpacity=".12" cx="342" cy="31.303" rx="8" ry="7.788" />
                    <ellipse fillOpacity=".48" cx="62" cy="126.811" rx="2" ry="1.947" />
                    <ellipse fillOpacity=".12" cx="78" cy="7.072" rx="2" ry="1.947" />
                    <ellipse fillOpacity=".64" cx="185" cy="15.576" rx="6" ry="5.841" />
                  </g>
                  <circle fill="url(#ni-a)" cx="276" cy="237" r="200" />
                </g>
              </svg>
            </div>

            <div className="relative flex flex-col lg:flex-row justify-between items-center">

              {/* CTA content */}
              <div className="text-center lg:text-left lg:max-w-xl">
                <p className="text-xl text-white mb-5"><b>Interested in being featured on the platform?</b></p>
                <p className="text-gray-300 text-lg mb-6">We have a strong user base, particularly new homeowners looking for renovation deals. We are looking to forge business partnerships with merchants who would like to serve a targeted audience. Drop us a note to learn how to be featured, or feel free to suggest features to us!</p>

                {/* CTA form */}
                <form className="w-full lg:w-auto" ref={formRef} onSubmit={handleSubmit} name="google-sheet">
                  <div className="mb-5 flex flex-col sm:flex-row justify-center max-w-xs mx-auto sm:max-w-md lg:mx-0">
                    <input type="email" name="email" className="form-input w-full appearance-none bg-gray-800 border border-gray-700 focus:border-gray-600 rounded-sm px-4 py-3 mb-2 sm:mb-0 sm:mr-2 text-white placeholder-gray-300" placeholder="Your email…" aria-label="Your email…" />
                  </div>
                  <div className="mb-5 flex flex-col sm:flex-row justify-center max-w-xs mx-auto sm:max-w-md lg:mx-0">
                    <textarea className="form-input w-full appearance-none bg-gray-800 border border-gray-700 focus:border-gray-600 rounded-sm px-4 py-3 mb-2 sm:mb-0 sm:mr-2 text-white placeholder-gray-300" placeholder="What would you like to tell us?" aria-label="Your comment..." name="comment" rows="5"></textarea>
                  </div>
                  <div className="mb-5 flex flex-col sm:flex-row max-w-xs mx-auto sm:max-w-md lg:mx-0">
                    <div className="top-full flex items-center transform bg-green-500 text-white rounded font-medium group p-4 shadow-lg">
                      <input type="submit" value={loading ? "Loading..." : "Get in touch with us!"} />
                    </div>

                  </div>
                </form>
              </div>

            </div>

          </div>

        </div>
      </div>
    </section>
  );
}

export default Newsletter;



const FormStyle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    .container{
        background-color: #F9CFF2;
        margin-top: 10%;
        padding: 10rem 10rem 10rem 10rem;
        display: flex;
        justify-content: center;
        align-items: center;
        @media(max-width: 610px){
            padding: 4rem 4rem 4rem 4rem;
        }

        .input-style{
            padding-top: 0.8em;
            display: flex;
            flex-direction: column;
            gap: 0.8em;

            label{
                font-family: 'Poppins', sans-serif;
            }

            input{
                outline: none;
                border: none;
                padding: 0.8em;
                border-radius: 2em;
            }
            input[type=submit]{
                background-color: #52154E;
                color: #FFFFFFFF;
                font-weight: bold;


            }
        }

    }

`
