import React from 'react';
//import GoogleLogin from 'react-google-login';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { FcGoogle } from 'react-icons/fc';
import shareVideo from '../assets/share2.mp4';
import logo from '../assets/AI-bw.png';
import jwt_decode from 'jwt-decode'
import { client } from '../client'


import Header from '../partials/Header';
import HeroHome from '../partials/HeroHome';
import FeaturesHome from '../partials/Features';
import FeaturesBlocks from '../partials/FeaturesBlocks';
import Testimonials from '../partials/Testimonials';
import Newsletter from '../partials/Newsletter';
import Footer from '../partials/Footer';
import Banner from '../partials/Banner';

const Login = () => {
  // const navigate = useNavigate();
  // const responseGoogle = (response) => {
  //   localStorage.setItem('user', JSON.stringify(response.profileObj));
  //   const { name, googleId, imageUrl } = response.profileObj;
  //   const doc = {
  //     _id: googleId,
  //     _type: 'user',
  //     userName: name,
  //     image: imageUrl,
  //   };
  //   client.createIfNotExists(doc).then(() => {
  //     navigate('/', { replace: true });
  //   });
  // };

  const navigate = useNavigate()

  const responseGoogle = (response) => {
    localStorage.setItem('user', JSON.stringify(response.credential));
    //console.log(response.credential)
    const { name, sub, picture, email } = jwt_decode(response.credential); // here
    //console.log({ name, sub, picture });

    // save user as a Sanity 'user' document
    const doc = {
      _id: sub,               // here
      _type: 'user',
      address: email,
      userName: name,
      image: picture,  // here
      credits: 5
    };

    client.createOrReplace(doc).then(() => {
      navigate('/', { replace: true });
    });

  }

  return (
    // <div className="flex justify-start items-center flex-col h-screen">
    //   <div className=" relative w-full h-full">
    //     <video
    //       src={shareVideo}
    //       type="video/mp4"
    //       loop
    //       controls={false}
    //       muted
    //       autoPlay
    //       className="w-full h-full object-cover"
    //     />

    //     <div className="absolute flex flex-col justify-center items-center top-0 right-0 left-0 bottom-0 bg-blackOverlay">

    //       {/* <div className="p-5 w-1/2 h-1/3">
    //         <img src={logo} width="w-1/2" className='object-center' />
    //       </div> */}

    //       <div className='pb-5'>
    //         <img src={logo} width="320px"/>
    //       </div>


    //       <div className="flex justify-center items-center shadow-2xl">
    //         <GoogleLogin
    //           clientId={`${process.env.REACT_APP_GOOGLE_API_TOKEN}`}
    //           onSuccess={responseGoogle}
    //           onFailure={responseGoogle}
    //           cookiePolicy="single_host_origin"
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page sections */}
        <HeroHome />
        <FeaturesHome />
        <FeaturesBlocks />
        <Testimonials />
        <Newsletter />

      </main>

      {/* <Banner /> */}

      {/*  Site footer */}
      <Footer />

    </div>
  );
};

export default Login