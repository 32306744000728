import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { client } from '../client'
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
import logo from '../assets/AI-4.png';

function Header() {

  const [top, setTop] = useState(true);

  // detect whether user has scrolled the page down by 10px 
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true)
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  const responseGoogle = (response) => {
    localStorage.setItem('user', JSON.stringify(response.credential));

    const { name, sub, picture, email } = jwt_decode(response.credential); // here
    //console.log({ name, sub, picture });

    // save user as a Sanity 'user' document
    const doc = {
      _id: sub,               // here
      _type: 'user',
      address: email,
      userName: name,
      image: picture,    // here
    };

    client.createIfNotExists(doc).then(() => {
      navigate('/', { replace: true });
    });

  }

  const navigate = useNavigate()

  return (
    <header className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${!top && 'bg-white backdrop-blur-sm shadow-lg'}`}>
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">

          {/* Site branding */}
          <div className="mr-4">
            {/* Logo */}

              <img src={logo} alt="logo" className="w-1/3" />

          </div>

          {/* Site navigation */}
          <nav className="flex flex-grow justify-end">
            <div className="flex justify-center items-center shadow-2xl">
              <GoogleLogin
                clientId={`${process.env.REACT_APP_GOOGLE_API_TOKEN}`}
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                size="medium"
                cookiePolicy="single_host_origin"
              />
            </div>

          </nav>

        </div>
      </div>
    </header>
  );
}

export default Header;
