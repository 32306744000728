import React, { useState } from "react";


const About = () => {
  return (
    <div>
      <div className="lg:p-8 flex border bg-white p-4 rounded-md flex-col justify-center mt-5 lg:h-4/5">
        <h3 className="text-base pb-4 2xl:text-xl"><b>😊 Welcome</b></h3>
        <p>Hi, welcome to AI Home Design! This app allows users to tap on Stable Diffusion to create, explore and share interior design ideas. Think of it like Pinterest + Instagram + Midjourney, for interior design! </p>
      </div>

      <div className="lg:p-8 flex border bg-white p-4 rounded-md flex-col justify-center mt-5 lg:h-4/5">
        <h3 className="text-base pb-4 2xl:text-xl"><b>🔧 Prompt Engineering</b></h3>
        <p>
          Trying to get Stable Diffusion to give good results requires some trial and error. From my experience, the following format works best: specify the room (e.g. living room), then provide stylistic details or description of the room, followed by the type of property. See below:
        </p>
        <div className="lg:p-6 flex border bg-white p-4 rounded-md flex-col justify-center my-5 lg:h-4/5">
          <b><i>"Living room in a Scandinavian style with a green sofa and lots of space in a condominium"</i></b>
        </div>
        <p>
          <u>Interesting hacks I've found</u>
          <br></br>
          <br></br>
          1. Specifying colours with the word "tones" tend to lead to rather accurate pictures.<br></br><br></br>
          2. Place whatever you want to emphasise in front. <b>"Living room with a Scandinavian..."</b> places emphasis on the style, while <b>"living room with a green sofa in a Scandi..."</b> prioritises the sofa.<br></br><br></br>
          3. For Singapore-based users, you can use the optional field to set "HDB". It will append "in a HDB" at the end of your prompt. I found this to be the right balance between aesthetics and realism.
        </p>
      </div>

      <div className="lg:p-8 flex border bg-white p-4 rounded-md flex-col justify-center mt-5 lg:h-4/5">
        <h3 className="text-base pb-4 2xl:text-xl"><b>🌏 Community guidelines</b></h3>
        <p>While the prompt fields are engineered to maximise the chances of high quality home impressions, I have left the description field open for users to exercise creative expression.
          <br></br><br></br>
          Please use this creative freedom properly to generate home designs. By keeping the space concentrated with good interior design ideas, everyone (including yourself) has a better user experience.
          <br></br><br></br>
          For other purposes, you can use the <a href="https://huggingface.co/spaces/stabilityai/stable-diffusion" target="_blank"><b><u>implementation on HuggingFace</u></b></a> as they may have better weights for general purpose use there. 
          <br></br><br></br>
          Do note that I will remove images that do not benefit the community.
        </p>
      </div>

      <div className="mb-10 lg:p-8 flex border bg-white p-4 rounded-md flex-col justify-center mt-5 lg:h-4/5">
        <h3 className="text-base pb-4 2xl:text-xl"><b>👦🏻 About me</b></h3>
        <p>I’m a data professional and developer based in Singapore. I build things that help people live more creatively and intuitively. Connect with me <a href="https://www.linkedin.com/in/tanweilie/" target="_blank"><b><u>here</u></b></a>!
          <br></br>
          <br></br>
          AI HD was a personal stretch project to sharpen my skills, particuarly in database management, frontend dev, and product thinking (e.g. user acquisition, community management).
          <br></br>
          <br></br>
          Like AI HD, my other products are inspired by personal need or pain points I observed. Check out <a href="https://schemes.sg" target="_blank"><b><u>Schemes SG</u></b></a>.
        </p>
      </div>

    </div>
  );
}
export default About

// import {useState} from 'react';

// const App = () => {
//   const [data, setData] = useState({data: []});
//   const [isLoading, setIsLoading] = useState(false);
//   const [err, setErr] = useState('');

//   const apiKey = process.env.DREAMSTUDIO_API_KEY

//   const handleClick = async () => {
//     try {
//       const response = await fetch('https://api.stability.ai/v1alpha/generation/stable-diffusion-512-v2-0/text-to-image', {
//         method: 'POST',
//         headers: {
//           Accept: 'application/json',
//           Authorization: apiKey,
//         },
//         body: JSON.stringify({
//           "cfg_scale": 7,
//           "clip_guidance_preset": "FAST_BLUE",
//           "height": 512,
//           "sampler": "K_DPM_2_ANCESTRAL",
//           "samples": 2,
//           "seed": 0,
//           "steps": 75,
//           "text_prompts": [
//             {
//               "text": "A lighthouse on a cliff",
//               "weight": 1
//             }
//           ],
//           "width": 512
//         })
//       });

//       if (!response.ok) {
//         throw new Error(`Error status: ${response.status}`);
//       }

//       const result = await response.json();
//       console.log(response)

//       setData(result);
//     } catch (err) {
//       setErr(err.message);
//     }
//   };

//   return (
//     <div>
//       {err && <h2>{err}</h2>}

//       <button onClick={handleClick}>Fetch data</button>

//     </div>
//   );
// };

// export default App;














// import React, { useState } from 'react';
// import { AiOutlineCloudUpload } from 'react-icons/ai';
// import { useNavigate } from 'react-router-dom';
// import { MdDelete } from 'react-icons/md';

// import { categories } from '../utils/data';
// import { client } from '../client';
// import Spinner from './Spinner';

// const apiHost = process.env.API_HOST ?? 'https://api.stability.ai'
// const url = `${apiHost}/v1alpha/user/account`

// const apiKey = process.env.DREAMSTUDIO_API_KEY
// console.log(apiKey)

// //-------------------

// const response = fetch(
// 	url,
// 	{
// 		method: 'POST',
// 		headers: {
// 			'Content-Type': 'application/json',
// 			Accept: 'image/png',
// 			Authorization: apiKey,
// 		},
// 		body: JSON.stringify({
// 			cfg_scale: 7,
// 			clip_guidance_preset: 'FAST_BLUE',
// 			height: 512,
// 			width: 512,
// 			samples: 1,
// 			steps: 50,
// 			text_prompts: [
// 				{
// 					text: 'A lighthouse on a cliff',
// 					weight: 1
// 				}
// 			],
// 		})
// 	}
// );

// if (!response.ok) {
// 	throw new Error(`Non-200 response: ${await response.text()}`);
// }

// try {
// 	console.log('hello');
// } catch (e) {
// 	console.error(e);
// }

// //======================




// const CreatePinCopy = ({ user }) => {
//   const [title, setTitle] = useState('');
//   const [about, setAbout] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [destination, setDestination] = useState();
//   const [fields, setFields] = useState();
//   const [category, setCategory] = useState();
//   const [imageAsset, setImageAsset] = useState();
//   const [wrongImageType, setWrongImageType] = useState(false);

//   const navigate = useNavigate();

//   console.log(process.env.DREAMSTUDIO_API_KEY)


//   const uploadImage = (e) => {
//     const selectedFile = e.target.files[0];
//     // uploading asset to sanity
//     if (selectedFile.type === 'image/png' || selectedFile.type === 'image/svg' || selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/gif' || selectedFile.type === 'image/tiff') {
//       setWrongImageType(false);
//       setLoading(true);
//       client.assets
//         .upload('image', selectedFile, { contentType: selectedFile.type, filename: selectedFile.name })
//         .then((document) => {
//           setImageAsset(document);
//           setLoading(false);
//         })
//         .catch((error) => {
//           console.log('Upload failed:', error.message);
//         });
//     } else {
//       setLoading(false);
//       setWrongImageType(true);
//     }
//   };

//   const savePin = () => {
//     if (title && about && destination && imageAsset?._id && category) {
//       const doc = {
//         _type: 'pin',
//         title,
//         about,
//         destination,
//         image: {
//           _type: 'image',
//           asset: {
//             _type: 'reference',
//             _ref: imageAsset?._id,
//           },
//         },
//         userId: user._id,
//         postedBy: {
//           _type: 'postedBy',
//           _ref: user._id,
//         },
//         category,
//       };
//       client.create(doc).then(() => {
//         navigate('/');
//       });
//     } else {
//       setFields(true);

//       setTimeout(
//         () => {
//           setFields(false);
//         },
//         2000,
//       );
//     }
//   };
//   return (
//     <div className="flex flex-col justify-center items-center mt-5 lg:h-4/5">
//       {fields && (
//         <p className="text-red-500 mb-5 text-xl transition-all duration-150 ease-in ">Please add all fields.</p>
//       )}
//       <div className=" flex lg:flex-row flex-col justify-center items-center bg-white lg:p-5 p-3 lg:w-4/5  w-full">
//         <div className="bg-secondaryColor p-3 flex flex-0.7 w-full">
//           <div className=" flex justify-center items-center flex-col border-2 border-dotted border-gray-300 p-3 w-full h-420">
//             {loading && (
//               <Spinner />
//             )}
//             {
//               wrongImageType && (
//                 <p>It&apos;s wrong file type.</p>
//               )
//             }
//             {!imageAsset ? (
//               // eslint-disable-next-line jsx-a11y/label-has-associated-control
//               <label>
//                 <div className="flex flex-col items-center justify-center h-full">
//                   <div className="flex flex-col justify-center items-center">
//                     <p className="font-bold text-2xl">
//                       <AiOutlineCloudUpload />
//                     </p>
//                     <p className="text-lg">Click to upload</p>
//                   </div>

//                   <p className="mt-32 text-gray-400">
//                     Recommendation: Use high-quality JPG, JPEG, SVG, PNG, GIF or TIFF less than 20MB
//                   </p>
//                 </div>
//                 <input
//                   type="file"
//                   name="upload-image"
//                   onChange={uploadImage}
//                   className="w-0 h-0"
//                 />
//               </label>
//             ) : (
//               <div className="relative h-full">
//                 <img
//                   src={imageAsset?.url}
//                   alt="uploaded-pic"
//                   className="h-full w-full"
//                 />
//                 <button
//                   type="button"
//                   className="absolute bottom-3 right-3 p-3 rounded-full bg-white text-xl cursor-pointer outline-none hover:shadow-md transition-all duration-500 ease-in-out"
//                   onClick={() => setImageAsset(null)}
//                 >
//                   <MdDelete />
//                 </button>
//               </div>
//             )}
//           </div>
//         </div>

//         <div className="flex flex-1 flex-col gap-6 lg:pl-5 mt-5 w-full">
//           <input
//             type="text"
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//             placeholder="Add your title"
//             className="outline-none text-2xl sm:text-3xl font-bold border-b-2 border-gray-200 p-2"
//           />
//           {user && (
//             <div className="flex gap-2 mt-2 mb-2 items-center bg-white rounded-lg ">
//               <img
//                 src={user.image}
//                 className="w-10 h-10 rounded-full"
//                 alt="user-profile"
//               />
//               <p className="font-bold">{user.userName}</p>
//             </div>
//           )}
//           <input
//             type="text"
//             value={about}
//             onChange={(e) => setAbout(e.target.value)}
//             placeholder="Tell everyone what your Pin is about"
//             className="outline-none text-base sm:text-lg border-b-2 border-gray-200 p-2"
//           />
//           <input
//             type="url"
//             vlaue={destination}
//             onChange={(e) => setDestination(e.target.value)}
//             placeholder="Add a destination link"
//             className="outline-none text-base sm:text-lg border-b-2 border-gray-200 p-2"
//           />

//           <div className="flex flex-col">
//             <div>
//               <p className="mb-2 font-semibold text:lg sm:text-xl">Choose Pin Category</p>
//               <select
//                 onChange={(e) => {
//                   setCategory(e.target.value);
//                 }}
//                 className="outline-none w-4/5 text-base border-b-2 border-gray-200 p-2 rounded-md cursor-pointer"
//               >
//                 <option value="others" className="sm:text-bg bg-white">Select Category</option>
//                 {categories.map((item) => (
//                   <option className="text-base border-0 outline-none capitalize bg-white text-black " key={item.name} value={item.name}>
//                     {item.name}
//                   </option>
//                 ))}
//               </select>
//             </div>
//             <div className="flex justify-end items-end mt-5">
//               <button
//                 type="button"
//                 onClick={savePin}
//                 className="bg-red-500 text-white font-bold p-2 rounded-full w-28 outline-none"
//               >
//                 Save Pin
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CreatePinCopy;