import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IoMdBrush, IoMdSearch } from 'react-icons/io';
import { SlMagicWand } from 'react-icons/sl'

const Navbar = ({ searchTerm, setSearchTerm, user }) => {
  const navigate = useNavigate();
  const setScandi = () =>{ setSearchTerm("Scandinavian");navigate('/search')}
  const setJapandi = () =>{ setSearchTerm("Japandi");navigate('/search')}
  const setModern = () =>{ setSearchTerm("Modern");navigate('/search')}
  const setIndustrial = () =>{ setSearchTerm("Industrial");navigate('/search')}
  const setMinimalist = () =>{ setSearchTerm("Minimalist");navigate('/search')}
  const setSimple= () =>{ setSearchTerm("Simple");navigate('/search')}
  const setCountry = () =>{ setSearchTerm("Country");navigate('/search')}
  const setRustic = () =>{ setSearchTerm("Rustic");navigate('/search')}
  const setBohe = () =>{ setSearchTerm("Bohemian");navigate('/search')}
  if (user) {
    return (
      <div>
        <div className="flex gap-2 items-left md:gap-5 w-full mt-5 overflow-y-scroll">
          <button className="font-bold p-2 mt-1 rounded-full mb-1 w-96 outline-none" > Styles: </button>
          <button className="bg-gray-300 font-bold p-2 mt-1 mx-2 rounded-full mb-1 outline-none" onClick={setScandi}> Scandinavian </button>
          <button className="bg-gray-300 font-bold p-2 mt-1 mx-2 rounded-full mb-1 outline-none" onClick={setJapandi}> Japandi </button>
          <button className="bg-gray-300 font-bold p-2 mt-1 mx-2 rounded-full mb-1 outline-none" onClick={setModern}> Modern </button>
          <button className="bg-gray-300 font-bold p-2 mt-1 mx-2 rounded-full mb-1 outline-none" onClick={setIndustrial}> Industrial </button>
          <button className="bg-gray-300 font-bold p-2 mt-1 mx-2 rounded-full mb-1 outline-none" onClick={setMinimalist}> Minimalist </button>
          <button className="bg-gray-300 font-bold p-2 mt-1 mx-2 rounded-full mb-1 outline-none" onClick={setSimple}> Simple </button>
          <button className="bg-gray-300 font-bold p-2 mt-1 mx-2 rounded-full mb-1 outline-none" onClick={setCountry}> Countryside </button>
          <button className="bg-gray-300 font-bold p-2 mt-1 mx-2 rounded-full mb-1 outline-none" onClick={setRustic}> Rustic </button>
          <button className="bg-gray-300 font-bold p-2 mt-1 mx-2 rounded-full mb-1 outline-none" onClick={setBohe}> Bohemian </button>
        </div>
        <div className="flex gap-2 md:gap-5 w-full mt-5 pb-4 ">
          <div className="flex justify-start items-center w-full px-2 roundedmd bg-white border-none outline-none focus-within:shadow-sm">
            <IoMdSearch fontSize={21} className="ml-1" />
            <input
              type="text"
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search using keywords"
              value={searchTerm}
              onFocus={() => navigate('/search')}
              className="p-2 w-full bg-white outline-none"
            />
          </div>

          <div className="flex gap-3 ">
            <Link to={`user-profile/${user?._id}`} className="hidden md:block">
              <img src={user.image} alt="user-pic" className="w-14 h-12 rounded-lg " referrerPolicy="no-referrer" />
            </Link>
            <Link to="/create-with-ai" className="bg-black text-white rounded-lg w-12 h-12 md:w-14 md:h-12 flex justify-center items-center">
              <IoMdBrush />
            </Link>
            <Link to="/reimagine" className="bg-black text-white rounded-lg w-12 h-12 md:w-14 md:h-12 flex justify-center items-center">
              <SlMagicWand />
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default Navbar;