import React, { useState } from "react";
import Spinner from "./Spinner";
import CountUp from 'react-countup'
import { categories } from '../utils/data';
import { useEffect, useRef } from "react";
import { client } from '../client';
import { useNavigate } from 'react-router-dom';
import sadface from '../assets/sadface.png';

const CreatePinCopy = ({ user }) => {
  const navigate = useNavigate();


  const [isLoading, setIsLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useState("TBD");
  const [category, setCategory] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [about, setAbout] = useState('');
  const [source, setSource] = useState('ai')
  const [desc, setDesc] = useState('')
  const [destination, setDestination] = useState('');
  const [title, setTitle] = useState('');
  const [saveLoading, setSaveLoading] = useState(false)
  const [validation, setValidation] = useState('')
  const [promptDisplay, setPromptDisplay] = useState('')

  const scrollRef = useRef(null)

  useEffect(() => {
    setAbout(category.toLowerCase() + " " + desc + " " + propertyType);
  }, [category, desc, propertyType])

  useEffect(() => {
    setPromptDisplay(about)
  }, [about])

  const fetchData = () => {
    if (category !== ''){
    setIsLoading(true);
    setValidation('')
    setStatusMessage("")

    fetch(process.env.REACT_APP_DREAMSTUDIO_ENDPOINT, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json; charset=utf-8',
        "Accept": 'application/json',
        "Authorization": process.env.REACT_APP_DREAMSTUDIO_API_KEY,
      },
      body: JSON.stringify({
        cfg_scale: 6,
        clip_guidance_preset: 'FAST_BLUE',
        height: 512,
        width: 512,
        samples: 1,
        steps: 60,
        text_prompts: [
          {
            text: "Photograph of a well-lit " + about,
            weight: 1
          }
        ],
      })
    })
      .then((response) => response.json())
      .then((response) => {
        setStatusMessage(response.artifacts[0].base64);
        setIsLoading(false);
      })
      .catch(() => {
        setStatusMessage("ERROR");
        setIsLoading(false);
      })
    } else {
      //console.log('fill up fields!');
      setValidation('missing')
    }
  };

  const savePin = () => {
    setSaveLoading(true)
    //console.log(saveLoading)
    const dataurl = `data:image/jpeg;base64,${statusMessage}`

    const dataURLtoFile = (dataurl, filename) => {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }
    //Upload file to Sanity CDN
    const uploadImage = () => {
      if (transferfile.type === 'image/png' || transferfile.type === 'image/svg' || transferfile.type === 'image/jpeg' || transferfile.type === 'image/gif' || transferfile.type === 'image/tiff') {
        //console.log(transferfile)
        client.assets
          .upload('image', transferfile, { contentType: transferfile.type, filename: transferfile.name })
          .then((document) => {
            //console.log(document)

            const doc = {
              _type: 'pin',
              title,
              about,
              destination,
              source,
              image: {
                _type: 'image',
                asset: {
                  _type: 'reference',
                  _ref: document?._id,
                },
              },
              userId: user._id,
              postedBy: {
                _type: 'postedBy',
                _ref: user._id,
              },
              category,
            };

            //console.log(doc)

            client.create(doc).then(() => {
              setSaveLoading(false)
              //console.log(saveLoading)
              navigate(`/user-profile/${user._id}`);
            })
          })
          .catch((error) => {
            //console.log('Upload failed:', error.message);
          });
      } else {
        //console.log('not done')
      }
    };

    const transferfile = dataURLtoFile(dataurl, 'hello.jpeg')
    uploadImage();
  };

  //const executeScroll = () => scrollRef.current.scrollIntoView({ behavior: 'smooth' })

  return (

    <div className="flex flex-col justify-center items-center mt-5 lg:h-4/5">

      <div className="h-full pb-9">
        {isLoading &&
          <div className="text-center">
            <Spinner />
            <br></br>
            <CountUp start={0} end={100} duration={27} delay={0}>
              {({ countUpRef }) => (
                <div >
                  Loading about <b><span ref={countUpRef} /> %</b>
                </div>
              )}
            </CountUp>
            {/* <h4 ><i>This usually takes about 10 to 15 seconds...</i></h4> */}
          </div>}
        
        {statusMessage.length > 10 ?
          <div className="flex-col text-center mt-5">
            <img className="w-full" src={`data:image/jpeg;base64,${statusMessage}`} />
            <br></br>
            ❤️ <b><i><u>Love this image? Save it.</u> Otherwise, you will not get it back later when re-generating!</i></b>
            <br></br>
            <br></br>
            <label>Set title:
              <input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="E.g. Interesting room"
                className="ml-1 flex-col rounded-md outline-none border-b-2 border-gray-200 p-2"
              />
            </label>
            <br></br>
            <label>Set hashtags:
              <input
                value={destination}
                onChange={(e) => setDestination(e.target.value)}
                placeholder="E.g. #chic #soholiving #sleek"
                className="ml-1 flex-col rounded-md outline-none border-b-2 border-gray-200 p-2"
              />
            </label>
            <br></br>
            <button className="bg-green-500 text-white font-bold p-2 mt-1 mx-2 rounded-full mb-4 w-40 outline-none" onClick={savePin} >  {saveLoading === false ? "Save image" : "Loading..."} </button>

          </div>
          :

          <>

          {statusMessage === "ERROR" ?
          
          <div className="flex p-3 justify-center">
            <div className="flex flex-1 flex-col w-full p-3 items-center justify-center">
              <div className="flex flex-1 items-center flex-col w-full">
                <img
                  src={sadface}
                  alt="sadface"
                  className="h-20 w-20"
                />
              </div>
              <div className="flex-1 flex-col text-center flex w-full">
                <br></br>
                <b>Oh no! You have hit an error. This is either due to bandwidth issues (in which case you can just refresh), or the app was so unexpectedly popular that it ran out of credits (give us awhile, we will fix this)!</b>
              </div>
            </div>
          </div>

          :

          <div></div>}
          
          </>
          }



      </div>

      {validation === 'missing' ? <div className="flex text-red-500 rounded-lg flex-col text-center lg:w-3/5 p-2 mb-4">
        <b>Please fill in minimally the Category (Step 1). For best results, complete Steps 1 and 2, with 3 being an optional step.</b>

      </div> : <div></div>}


      <div className="flex border-4 rounded-lg flex-col text-center lg:w-3/5 p-2 mb-4">
        <b>Generate a prompt by completing this phrase:</b>
        <i>A photograph of a... {promptDisplay}</i>
      </div>

      <div className="flex rounded-lg flex-col text-center lg:w-3/5 p-2 mb-4">
        <p className="text-xs"><i>First time? <a href="/about"><u>Read our prompt engineering and community guidelines.</u></a> </i></p>
      </div>

      

      <div className="flex flex-col w-full text-center lg:w-3/5">
        <div>
          <p className="mb-2 font-semibold text:lg sm:text-xl">Step 1</p>
          <select
            onChange={(e) => {
              setCategory(e.target.value);
            }}
            className="outline-none w-full text-base border-b-2 border-gray-200 p-2 rounded-md cursor-pointer"
          >
            <option className="text-center border-0 outline-none capitalize bg-white text-black " value="">
              Select the area of interest
            </option>
            {categories.map((item) => (
              <option className="text-center border-0 outline-none capitalize bg-white text-black " key={item.name} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <br></br>

        <div className="flex flex-1 flex-col w-full">
          <p className="mt-4 mb-2 font-semibold text:lg sm:text-xl">Step 2: Imagine and describe!</p>
          <textarea
            rows={3}
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
            placeholder="...with a Scandinavian design, pastel colored walls and a green sofa. There are baby toys in the middle."
            className="rounded-md outline-none border-b-2 border-gray-200 p-2"
          />
        </div>

        <div ref={scrollRef}>
          <p className="mt-4 font-semibold text:lg sm:text-xl">Step 3: Property Type (optional)</p>
          <select
            onChange={(e) => {
              setPropertyType(e.target.value);
            }}
            className="outline-none w-full text-center border-b-2 border-gray-200 p-2 rounded-md cursor-pointer"
          >
            <option className="text-center border-0 outline-none bg-white text-black " value="">
              Select the property type
            </option>
            <option className="text-base border-0 outline-none bg-white text-black " value="in a HDB.">HDB</option>
            <option className="text-base border-0 outline-none bg-white text-black " value="in a condominium.">Condominium</option>
            <option className="text-base border-0 outline-none bg-white text-black " value="in a terrace.">Terrace</option>
          </select>
        </div>
        <br></br>

        <div className="flex-col text-center mt-5">
          <button className="bg-red-500 text-white font-bold p-2 rounded-full mb-4 w-40 outline-none" onClick={fetchData}>Generate Image</button>
        </div>

      </div>
    </div>

  );
}
export default CreatePinCopy

// import {useState} from 'react';

// const App = () => {
//   const [data, setData] = useState({data: []});
//   const [isLoading, setIsLoading] = useState(false);
//   const [err, setErr] = useState('');

//   const apiKey = process.env.DREAMSTUDIO_API_KEY

//   const handleClick = async () => {
//     try {
//       const response = await fetch('https://api.stability.ai/v1alpha/generation/stable-diffusion-512-v2-0/text-to-image', {
//         method: 'POST',
//         headers: {
//           Accept: 'application/json',
//           Authorization: apiKey,
//         },
//         body: JSON.stringify({
//           "cfg_scale": 7,
//           "clip_guidance_preset": "FAST_BLUE",
//           "height": 512,
//           "sampler": "K_DPM_2_ANCESTRAL",
//           "samples": 2,
//           "seed": 0,
//           "steps": 75,
//           "text_prompts": [
//             {
//               "text": "A lighthouse on a cliff",
//               "weight": 1
//             }
//           ],
//           "width": 512
//         })
//       });

//       if (!response.ok) {
//         throw new Error(`Error status: ${response.status}`);
//       }

//       const result = await response.json();
//       console.log(response)

//       setData(result);
//     } catch (err) {
//       setErr(err.message);
//     }
//   };

//   return (
//     <div>
//       {err && <h2>{err}</h2>}

//       <button onClick={handleClick}>Fetch data</button>

//     </div>
//   );
// };

// export default App;














// import React, { useState } from 'react';
// import { AiOutlineCloudUpload } from 'react-icons/ai';
// import { useNavigate } from 'react-router-dom';
// import { MdDelete } from 'react-icons/md';

// import { categories } from '../utils/data';
// import { client } from '../client';
// import Spinner from './Spinner';

// const apiHost = process.env.API_HOST ?? 'https://api.stability.ai'
// const url = `${apiHost}/v1alpha/user/account`

// const apiKey = process.env.DREAMSTUDIO_API_KEY
// console.log(apiKey)

// //-------------------

// const response = fetch(
// 	url,
// 	{
// 		method: 'POST',
// 		headers: {
// 			'Content-Type': 'application/json',
// 			Accept: 'image/png',
// 			Authorization: apiKey,
// 		},
// 		body: JSON.stringify({
// 			cfg_scale: 7,
// 			clip_guidance_preset: 'FAST_BLUE',
// 			height: 512,
// 			width: 512,
// 			samples: 1,
// 			steps: 50,
// 			text_prompts: [
// 				{
// 					text: 'A lighthouse on a cliff',
// 					weight: 1
// 				}
// 			],
// 		})
// 	}
// );

// if (!response.ok) {
// 	throw new Error(`Non-200 response: ${await response.text()}`);
// }

// try {
// 	console.log('hello');
// } catch (e) {
// 	console.error(e);
// }

// //======================




// const CreatePinCopy = ({ user }) => {
//   const [title, setTitle] = useState('');
//   const [about, setAbout] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [destination, setDestination] = useState();
//   const [fields, setFields] = useState();
//   const [category, setCategory] = useState();
//   const [imageAsset, setImageAsset] = useState();
//   const [wrongImageType, setWrongImageType] = useState(false);

//   const navigate = useNavigate();

//   console.log(process.env.DREAMSTUDIO_API_KEY)


//   const uploadImage = (e) => {
//     const selectedFile = e.target.files[0];
//     // uploading asset to sanity
//     if (selectedFile.type === 'image/png' || selectedFile.type === 'image/svg' || selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/gif' || selectedFile.type === 'image/tiff') {
//       setWrongImageType(false);
//       setLoading(true);
//       client.assets
//         .upload('image', selectedFile, { contentType: selectedFile.type, filename: selectedFile.name })
//         .then((document) => {
//           setImageAsset(document);
//           setLoading(false);
//         })
//         .catch((error) => {
//           console.log('Upload failed:', error.message);
//         });
//     } else {
//       setLoading(false);
//       setWrongImageType(true);
//     }
//   };

//   const savePin = () => {
//     if (title && about && destination && imageAsset?._id && category) {
//       const doc = {
//         _type: 'pin',
//         title,
//         about,
//         destination,
//         image: {
//           _type: 'image',
//           asset: {
//             _type: 'reference',
//             _ref: imageAsset?._id,
//           },
//         },
//         userId: user._id,
//         postedBy: {
//           _type: 'postedBy',
//           _ref: user._id,
//         },
//         category,
//       };
//       client.create(doc).then(() => {
//         navigate('/');
//       });
//     } else {
//       setFields(true);

//       setTimeout(
//         () => {
//           setFields(false);
//         },
//         2000,
//       );
//     }
//   };
//   return (
//     <div className="flex flex-col justify-center items-center mt-5 lg:h-4/5">
//       {fields && (
//         <p className="text-red-500 mb-5 text-xl transition-all duration-150 ease-in ">Please add all fields.</p>
//       )}
//       <div className=" flex lg:flex-row flex-col justify-center items-center bg-white lg:p-5 p-3 lg:w-4/5  w-full">
//         <div className="bg-secondaryColor p-3 flex flex-0.7 w-full">
//           <div className=" flex justify-center items-center flex-col border-2 border-dotted border-gray-300 p-3 w-full h-420">
//             {loading && (
//               <Spinner />
//             )}
//             {
//               wrongImageType && (
//                 <p>It&apos;s wrong file type.</p>
//               )
//             }
//             {!imageAsset ? (
//               // eslint-disable-next-line jsx-a11y/label-has-associated-control
//               <label>
//                 <div className="flex flex-col items-center justify-center h-full">
//                   <div className="flex flex-col justify-center items-center">
//                     <p className="font-bold text-2xl">
//                       <AiOutlineCloudUpload />
//                     </p>
//                     <p className="text-lg">Click to upload</p>
//                   </div>

//                   <p className="mt-32 text-gray-400">
//                     Recommendation: Use high-quality JPG, JPEG, SVG, PNG, GIF or TIFF less than 20MB
//                   </p>
//                 </div>
//                 <input
//                   type="file"
//                   name="upload-image"
//                   onChange={uploadImage}
//                   className="w-0 h-0"
//                 />
//               </label>
//             ) : (
//               <div className="relative h-full">
//                 <img
//                   src={imageAsset?.url}
//                   alt="uploaded-pic"
//                   className="h-full w-full"
//                 />
//                 <button
//                   type="button"
//                   className="absolute bottom-3 right-3 p-3 rounded-full bg-white text-xl cursor-pointer outline-none hover:shadow-md transition-all duration-500 ease-in-out"
//                   onClick={() => setImageAsset(null)}
//                 >
//                   <MdDelete />
//                 </button>
//               </div>
//             )}
//           </div>
//         </div>

//         <div className="flex flex-1 flex-col gap-6 lg:pl-5 mt-5 w-full">
//           <input
//             type="text"
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//             placeholder="Add your title"
//             className="outline-none text-2xl sm:text-3xl font-bold border-b-2 border-gray-200 p-2"
//           />
//           {user && (
//             <div className="flex gap-2 mt-2 mb-2 items-center bg-white rounded-lg ">
//               <img
//                 src={user.image}
//                 className="w-10 h-10 rounded-full"
//                 alt="user-profile"
//               />
//               <p className="font-bold">{user.userName}</p>
//             </div>
//           )}
//           <input
//             type="text"
//             value={about}
//             onChange={(e) => setAbout(e.target.value)}
//             placeholder="Tell everyone what your Pin is about"
//             className="outline-none text-base sm:text-lg border-b-2 border-gray-200 p-2"
//           />
//           <input
//             type="url"
//             vlaue={destination}
//             onChange={(e) => setDestination(e.target.value)}
//             placeholder="Add a destination link"
//             className="outline-none text-base sm:text-lg border-b-2 border-gray-200 p-2"
//           />

//           <div className="flex flex-col">
//             <div>
//               <p className="mb-2 font-semibold text:lg sm:text-xl">Choose Pin Category</p>
//               <select
//                 onChange={(e) => {
//                   setCategory(e.target.value);
//                 }}
//                 className="outline-none w-4/5 text-base border-b-2 border-gray-200 p-2 rounded-md cursor-pointer"
//               >
//                 <option value="others" className="sm:text-bg bg-white">Select Category</option>
//                 {categories.map((item) => (
//                   <option className="text-base border-0 outline-none capitalize bg-white text-black " key={item.name} value={item.name}>
//                     {item.name}
//                   </option>
//                 ))}
//               </select>
//             </div>
//             <div className="flex justify-end items-end mt-5">
//               <button
//                 type="button"
//                 onClick={savePin}
//                 className="bg-red-500 text-white font-bold p-2 rounded-full w-28 outline-none"
//               >
//                 Save Pin
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CreatePinCopy;