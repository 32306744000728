import React, { useEffect, useState, useRef } from 'react';
import { MdDownloadForOffline } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';


import { client, urlFor } from '../client';
import MasonryLayout from './MasonryLayout';
import { pinDetailMorePinQuery, pinDetailQuery } from '../utils/data';
import Spinner from './Spinner';

const PinDetail = ({ user }) => {
  const { pinId } = useParams();
  const [pins, setPins] = useState();
  const [pinDetail, setPinDetail] = useState();
  const [items, setItems] = useState();
  const [comment, setComment] = useState('');
  const [itemLoading, setItemLoading] = useState(false)
  const [fetchItemLoad, setFetchItemLoad] = useState(false)
  const [addingComment, setAddingComment] = useState(false);
  const [HF, setHF] = useState('')
  const [picURL, setPicURL] = useState('')
  const [loadingNew, setLoadingNew] = useState(false);
  const [counter, setCounter] = useState(1)

  //-------for item manipulation later--------
  const itemDiv = document.getElementById("itemHolder")



  // ----------convert url to file--------
  const toDataURL = url => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    }))

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  // ----------convert url to file end--------
  //----------- carousel related functions----------------
  const maxScrollWidth = useRef(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carousel = useRef(null);

  const movePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const moveNext = () => {
    if (
      carousel.current !== null &&
      carousel.current.offsetWidth * currentIndex <= maxScrollWidth.current
    ) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const isDisabled = (direction) => {
    if (direction === 'prev') {
      return currentIndex <= 0;
    }

    if (direction === 'next' && carousel.current !== null) {
      return (
        carousel.current.offsetWidth * currentIndex >= maxScrollWidth.current
      );
    }

    return false;
  };

  useEffect(() => {
    if (carousel !== null && carousel.current !== null) {
      carousel.current.scrollLeft = carousel.current.offsetWidth * currentIndex;
    }
  }, [currentIndex]);

  useEffect(() => {
    maxScrollWidth.current = carousel.current
      ? carousel.current.scrollWidth - carousel.current.offsetWidth
      : 0;
  }, []);

  //----------- end carousel related function ---------------------

  const fetchPinDetails = () => {
    const query = pinDetailQuery(pinId);

    if (query) {
      client.fetch(`${query}`).then((data) => {
        setPinDetail(data[0]);
        setPicURL(data[0].image.asset.url)

        if (data[0]) {
          const query1 = pinDetailMorePinQuery(data[0]);
          client.fetch(query1).then((res) => {
            setPins(res.slice(0, 10));
          });
        }
      });
    }
  };


  const loadMore = () => {
    setCounter(counter + 1)
    setLoadingNew(true)
    console.log(counter)
    const query = pinDetailQuery(pinId);
    client.fetch(`${query}`).then((data) => {
      setPinDetail(data[0]);
      setPicURL(data[0].image.asset.url)
      if (data[0]) {
        const query1 = pinDetailMorePinQuery(data[0]);
        client.fetch(query1).then((res) => {
          setPins(res.slice(0, (counter + 1) * 10));
        });
        setLoadingNew(false)
      }
    });
  }

  const fetchitems = async (a) => {
    var obj
    setFetchItemLoad(true)
    const itemslist = await fetch('https://google-data-scraper.p.rapidapi.com/search/shop/' + a + '?api_key=' + process.env.REACT_APP_SCRAPERKEY, {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': process.env.REACT_APP_RAPIDAPIKEY,
        'X-RapidAPI-Host': 'google-data-scraper.p.rapidapi.com'
      }
    })
      .then(response => response.json())
      .then(data => { obj = Object.entries(data)[2][1].slice(0, 8) })
      //.then(() => { console.log(obj) })
      .then(() => { setItems(obj) })
      .catch(err => console.error(err));

    setFetchItemLoad(false)
  }


  async function hfquery(url) {

    setItemLoading(true)

    const itemHolder = document.getElementById("itemHolder");

    // Remove all child elements
    while (itemHolder.firstChild) {
      itemHolder.removeChild(itemHolder.firstChild);
    }

    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = url;
    await new Promise(resolve => img.onload = resolve);
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    canvas.toBlob(async blob => {
      const response = await fetch(
        process.env.REACT_APP_HFEP,
        {
          headers: { Authorization: "Bearer " + process.env.REACT_APP_HFKEY },
          method: "POST",
          body: blob,
        }
      );
      const result = await response.json();
      setHF(result)

      const labels = Array.from(new Set(result.map(item => item.label))) //.map(str => str.replace(/ /g, "%20")) after yellow bracket
      // console.log(labels)

      const linebreak = document.createElement("br");
      itemDiv.appendChild(linebreak)
      //create buttons
      for (let i = 0; i < labels.length; i++) {
        const newitembutton = document.createElement("button");
        newitembutton.className = "bg-white text-black rounded px-6 py-2 mr-3 font-semibold text-base outline-none"
        newitembutton.addEventListener('click', (e) => {
          fetchitems(labels[i].replace(/ /g, "%20"))
        })
        newitembutton.textContent = labels[i]
        itemDiv.appendChild(newitembutton);

      }

      setItemLoading(false)


    });
  }

  // hfquery(data[0].image.asset.url);

  useEffect(() => {
    if (HF.error === 'Model hustvl/yolos-small is currently loading') {
      alert("This is an MVP running on the free Inference API, so the model is still warming up! Give it about 2 minutes, and your experience should be smooth. Meanwhile, explore other people's creations!")
      setItemLoading(false)
    }
  }, [HF]);

  // useEffect(() => {
  //   console.log(picURL)
  // }, [picURL]);

  useEffect(() => {
    fetchPinDetails();
  }, [pinId]);

  // useEffect(() => {
  //   console.log(items);
  // }, [items]);


  const addComment = () => {
    if (comment) {
      setAddingComment(true);

      client
        .patch(pinId)
        .setIfMissing({ comments: [] })
        .insert('after', 'comments[-1]', [{ comment, _key: uuidv4(), postedBy: { _type: 'postedBy', _ref: user._id } }])
        .commit()
        .then(() => {
          fetchPinDetails();
          setComment('');
          setAddingComment(false);
        });
    }
  };

  if (!pinDetail) {
    return (
      <Spinner message="Showing image" />
    );
  }

  return (
    <>
      {pinDetail && (
        <div className="flex xl:flex-row flex-col m-auto bg-white" style={{ maxWidth: '1500px', borderRadius: '32px' }}>
          <div className="flex justify-center items-center md:items-start flex-initial">
            <img
              className="rounded-t-lg rounded-b-lg"
              src={(pinDetail?.image && urlFor(pinDetail?.image).url())}
              alt="user-post"
            />
          </div>
          <div className="w-full p-5 flex-1 xl:min-w-620">
            <div className="flex items-center justify-between">
              <div className="flex gap-2 items-center">
                <a
                  href={`${pinDetail.image.asset.url}?dl=`}
                  download
                  className="bg-secondaryColor p-2 text-xl rounded-full flex items-center justify-center text-dark opacity-75 hover:opacity-100"
                >
                  <MdDownloadForOffline />
                </a>
              </div>
              <a href={pinDetail.destination} target="_blank" rel="noreferrer">
                {pinDetail.destination}
              </a>
            </div>
            <div>
              <h1 className="text-4xl font-bold break-words mt-3">
                {pinDetail.title}
              </h1>
              <p className="mt-3">{pinDetail.about.charAt(0).toUpperCase() + pinDetail.about.substring(1)}</p>
            </div>
            <Link to={`/user-profile/${pinDetail?.postedBy._id}`} className="flex gap-2 mt-5 items-center bg-white rounded-lg ">
              <img src={pinDetail?.postedBy.image} className="w-10 h-10 rounded-full" alt="user-profile" />
              <p className="font-bold">{pinDetail?.postedBy.userName}</p>
            </Link>
            <h2 className="mt-5 text-2xl">Comments</h2>
            <div className="max-h-370 overflow-y-auto">
              {pinDetail?.comments?.map((item) => (
                <div className="flex gap-2 mt-5 items-center bg-white rounded-lg" key={item.comment}>
                  <img
                    src={item.postedBy?.image}
                    className="w-10 h-10 rounded-full cursor-pointer"
                    alt="user-profile"
                  />
                  <div className="flex flex-col">
                    <p className="font-bold">{item.postedBy?.userName}</p>
                    <p>{item.comment}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex flex-wrap mt-6 gap-3">
              <Link to={`/user-profile/${user._id}`}>
                <img src={user.image} className="w-10 h-10 rounded-full cursor-pointer" alt="user-profile" />
              </Link>
              <input
                className=" flex-1 border-gray-100 outline-none border-2 p-2 rounded-2xl focus:border-gray-300"
                type="text"
                placeholder="Add a comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <button
                type="button"
                className="bg-red-500 text-white rounded-full px-6 py-2 font-semibold text-base outline-none"
                onClick={addComment}
              >
                {addingComment ? 'Doing...' : 'Done'}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* <h2 className="text-2xl leading-8 font-semibold mt-5 mb-3 text-slate-700">Recreate this look</h2>

      <p>Using AI object recognition, you can identify products similar to those in this picture. You can then shop for these items.</p>
      <div className="max-h-370 overflow-y-auto">
        <button
          type="button"
          className="mt-5 bg-green-500 text-white rounded-full px-6 py-2 font-semibold text-base outline-none"
          onClick={async () => { await hfquery(picURL); }}
        >
          Trigger AI object recognition
        </button>
      </div>
      {itemLoading ? <div className='mt-6'><Spinner message="Recognising items..." /></div> : <></>}
      <div id="itemHolder"><div></div>
      </div>
      {fetchItemLoad ? <div className='mt-6'><Spinner message="Fetching items..." /></div> : <></>}

      {items ? <>
        <div className="carousel my-12 mx-auto">
          <h2 className="text-center text-2xl leading-8 font-semibold mb-5 text-slate-700">
            Items
          </h2>
          <div className="relative overflow-hidden">
            <div className="flex justify-between absolute top left w-full h-full">
              <button
                onClick={movePrev}
                className="bg-blue-900 hover:bg-blue-900/75 text-white w-10 h-full text-center opacity-75 hover:opacity-100 disabled:opacity-25 disabled:cursor-not-allowed z-10 p-0 m-0 transition-all ease-in-out duration-300"

              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-12 w-20 -ml-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
                <span className="sr-only">Prev</span>
              </button>
              <button
                onClick={moveNext}
                className="bg-blue-900 hover:bg-blue-900/75 text-white w-10 h-full text-center opacity-75 hover:opacity-100 disabled:opacity-25 disabled:cursor-not-allowed z-10 p-0 m-0 transition-all ease-in-out duration-300"

              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-12 w-20 -ml-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
                <span className="sr-only">Next</span>
              </button>
            </div>
            <div
              ref={carousel}
              className="mx-12 carousel-container relative flex gap-1 overflow-hidden scroll-smooth snap-x snap-mandatory touch-pan-x z-0"
            >
              {items.map((resource, index) => {
                return (
                  <div
                    key={index}
                    className="carousel-item text-center mx-4 relative w-64 h-64 snap-start"
                  >
                    <a
                      href={resource.link}
                      target="_blank"
                      className="h-full w-full aspect-square block bg-origin-padding bg-left-top bg-cover bg-no-repeat z-0"
                      style={{ backgroundImage: `url(${resource.thumbnail || ''})` }}
                    >
                      <img
                        src={resource.thumbnail || ''}
                        alt={resource.title}
                        className="w-full aspect-square hidden"
                      />
                    </a>
                    <a
                      href={resource.link}
                      target="_blank"
                      className="h-full w-full aspect-square block absolute top-0 left-0 transition-opacity duration-300 opacity-0 hover:opacity-100 bg-blue-800/75 z-10"
                    >
                      <p className="text-white py-6 px-3 mx-auto text-s">
                        <b>{resource.title}</b>
                      </p>
                      <p className="text-white py-3 px-3 mx-auto text-xs">
                        {resource.price}
                      </p>
                      <p className="text-white py-3 px-3 mx-auto text-xs">
                        From: {resource.source}
                      </p>
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </> : <><div></div></>} */}


      {pins?.length > 0 && (
        <h2 className="text-center font-bold text-2xl mt-8 mb-4">
          More {pinDetail.category.toLowerCase()} concepts
        </h2>
      )}
      {pins ? (
        <MasonryLayout pins={pins} />

      ) : (
        <Spinner message="Loading more pins" />
      )}


      {<div className='text-center'>
        <button className="bg-gray-300 font-bold p-2 mt-1 mx-2 rounded-full mb-4 w-40 outline-none" onClick={loadMore} > {loadingNew ? "..." : "Load More"} </button>
      </div>}

    </>
  );
};

export default PinDetail;