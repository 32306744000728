import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { client } from '../client';
import { feedQuery, searchQuery, numUsers } from '../utils/data';
import MasonryLayout from './MasonryLayout';
import Spinner from './Spinner';

const Feed = () => {
  const [pins, setPins] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingNew, setLoadingNew] = useState(false);
  const { categoryId } = useParams();
  const [counter, setCounter] = useState(0)

  const loadMore = () => {
    setLoadingNew(true);
    if (categoryId) {
      setCounter(counter + 1)
      const query = searchQuery(categoryId);
      client.fetch(query).then((data) => { //this is the function to fetch data from sanity. V important!
        setPins(data.slice(0, (counter + 2) * 10));
      });
      setLoadingNew(false)
    } 
    else
    { 
    setCounter(counter + 1)
    //console.log(counter)

    client.fetch(feedQuery).then((data) => { //feedQuery is to get ALL the posts, in the situation where there is no category specified

      setPins(data.slice(0, (counter + 2) * 10));
      setLoadingNew(false);
      //console.log(pins)
    })
  }};

  useEffect(() => {
    setCounter(counter);
  }, [counter])


  useEffect(() => {
    if (categoryId) {
      setLoading(true);
      const query = searchQuery(categoryId);
      client.fetch(query).then((data) => { //this is the function to fetch data from sanity. V important!
        setPins(data.slice(0, 10));
        setLoading(false);
      });
    } else {
      setLoading(true);

      client.fetch(feedQuery).then((data) => { //feedQuery is to get ALL the posts, in the situation where there is no category specified
        setPins(data.slice(0, 10));
        setLoading(false);
        //console.log(data)
      });
    }
  }, [categoryId]);

  client.fetch(numUsers).then((data) => { //feedQuery is to get ALL the posts, in the situation where there is no category specified
    //console.log("Number of users:" + data.length)
  });

  const ideaName = categoryId || 'new';

  if (loading) {
    return (
      <Spinner message={`We are adding ${ideaName} ideas to your feed!`} />
    );
  }

  if (!pins?.length) return <h2 className='text-center'>No images available</h2>

  return (
    <div>
      {pins && (
        <MasonryLayout pins={pins} />
      )}

      <div id="itemHolder"  className="text-center">
        <div>
          <button className="bg-gray-300 font-bold p-2 mt-1 mx-2 rounded-full mb-4 w-40 outline-none" onClick={loadMore} > {loadingNew ? "..." : "Load More"} </button>
        </div>
      </div>
    </div>
  );
};

export default Feed;