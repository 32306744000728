import React, { useRef, useEffect, useState } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import CountUp from 'react-countup'
import { categories, styles } from '../utils/data';
import { client } from '../client';
import Spinner from './Spinner';
import FormData from 'form-data';
import Compressor from 'compressorjs';



//---------

// import fetch from 'node-fetch';
// import FormData from 'form-data';
// import fs from "node:fs";


// const url = `https://api.stability.ai/v1alpha/generation/stable-diffusion-512-v2-0/image-to-image`

// const outputFile = `${process.env.OUT_DIR ?? "."}/image_to_image.png`
// const initImage = fs.readFileSync("../init_image.png")

// const formData = new FormData();
// formData.append('init_image', initImage);
// formData.append('options', JSON.stringify({
//   "cfg_scale": 7,
//   "clip_guidance_preset": "FAST_BLUE",
//   "step_schedule_start": 0.6,
//   "step_schedule_end": 0.01,
//   "height": 512,
//   "width": 512,
//   "samples": 1,
//   "steps": 50,
//   "text_prompts": [
//     {
//       "text": "A large spiral galaxy dog with a bright central bulge and a ring of stars around it",
//       "weight": 1
//     }
//   ],
// }));



// const response = await fetch(
//   url,
//   {
//     method: 'POST',
//     headers: {
//       ...formData.getHeaders(),
//       Accept: 'image/png',
//       Authorization: apiKey,
//     },
//     body: formData
//   }
// );

// if (!response.ok) {
//   throw new Error(`Non-200 response: ${await response.text()}`);
// }

// try {
//   const writeStream = fs.createWriteStream(outputFile);
//   response.body?.pipe(writeStream);
// } catch (e) {
//   console.error(e);}

// //------------


const CreatePin = ({ user }) => {
  const [title, setTitle] = useState('');
  const [about, setAbout] = useState('');
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [destination, setDestination] = useState('#reimagine');
  const [source, setSource] = useState('reimagine')
  const [fields, setFields] = useState();
  const [category, setCategory] = useState('Living Room');
  const [style, setStyle] = useState('Scandinavian');
  const [imageAsset, setImageAsset] = useState();
  const [croppedImageAsset, setCroppedImageAsset] = useState();
  const [wrongImageType, setWrongImageType] = useState(false);
  const [selectedBase64, setSelectedBase64] = useState()
  const [compressedFile, setCompressedFile] = useState(null);
  const [statusMessage, setStatusMessage] = useState();
  const [link, setLink] = useState('')
  const [saveLoading, setSaveLoading] = useState(false)
  const [prompt, setPrompt] = useState('')
  const myRef = useRef(null)


  useEffect(() => {
    setLink(statusMessage);
  }, [statusMessage])

  useEffect(() => {
    setTitle(style + ' ' + category);
  }, [style, category])


  useEffect(() => {
    setPrompt("photo of a " + `${category}`.toLowerCase() + " in a " + `${style}` + " style, 4k");
  }, [category, style])

  useEffect(() => {
    setAbout(category + " in a " + style + " style");
  }, [category, style])

 // console.log(about)

  const navigate = useNavigate();

  function base64encode(str) {
    return (encodeURIComponent(str));
  }

  const formData = new FormData();

  //crop image function

  const deletePic = () => {
    setCategory('living room');
    setStyle('Scandinavian');
    setImageAsset(null)
    setSelectedBase64(null)
  }

  function crop(url, aspectRatio) {
    return new Promise(resolve => {
      // this image will hold our source image data
      const inputImage = new Image();
      inputImage.crossOrigin = "anonymous";
      // we want to wait for our image to load
      inputImage.onload = () => {
        // let's store the width and height of our image
        const inputWidth = inputImage.naturalWidth;
        const inputHeight = inputImage.naturalHeight;
        // get the aspect ratio of the input image
        const inputImageAspectRatio = inputWidth / inputHeight;
        // if it's bigger than our target aspect ratio
        let outputWidth = inputWidth;
        let outputHeight = inputHeight;
        if (inputImageAspectRatio > aspectRatio) {
          outputWidth = inputHeight * aspectRatio;
        } else if (inputImageAspectRatio < aspectRatio) {
          outputHeight = inputWidth / aspectRatio;
        }
        // calculate the position to draw the image at
        const outputX = (outputWidth - inputWidth) * .5;
        const outputY = (outputHeight - inputHeight) * .5;
        // create a canvas that will present the output image
        const outputImage = document.createElement('canvas');
        // set it to the same size as the image
        outputImage.width = outputWidth;
        outputImage.height = outputHeight;
        // draw our image at position 0, 0 on the canvas
        const ctx = outputImage.getContext('2d');
        ctx.drawImage(inputImage, outputX, outputY);
        resolve(outputImage);
      };
      // start loading our image
      inputImage.src = url;
    });
  };

  function resizedataURL(datas, wantedWidth, wantedHeight) {
    return new Promise(async function (resolve, reject) {
      // We create an image to receive the Data URI
      var img = document.createElement('img');
      // When the event "onload" is triggered we can resize the image.
      img.onload = function () {
        // We create a canvas and get its context.
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        // We set the dimensions at the wanted size.
        canvas.width = wantedWidth;
        canvas.height = wantedHeight;
        // We resize the image with the canvas method drawImage();
        ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);
        var dataURI = canvas.toDataURL();
        // This is the return of the Promise
        resolve(dataURI);
      };
      // We put the Data URI in the image's sFrc attribute
      img.src = datas;
    })
  }// Use it like : var newDataURI = await resizedataURL('yourDataURIHere', 50, 50);

  // ----------convert url to file--------
  const toDataURL = url => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    }))

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  // ----------convert url to file end--------

  //------------convert file to dataurl-----------------
  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      // console.log(reader.result);
      setSelectedBase64(reader.result)
    };
    reader.onerror = function (error) {
      //console.log('Error: ', error);
    };
  }
  //------------convert file to dataurl end ------------

  const uploadImage = (e) => {
    const selectedFile = e.target.files[0];
    //console.log(selectedFile)

    var reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
     // console.log(reader.result)

      if (selectedFile.type === 'image/png' || selectedFile.type === 'image/svg' || selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/gif' || selectedFile.type === 'image/tiff') {
        setImageAsset('uploaded')
        setCroppedImageAsset()
        setWrongImageType(false);
        setLoading(true);
        crop(reader.result, 1).then((canvas) => {
          canvas.crossOrigin = 'Anonymous';
          (resizedataURL(`${canvas.toDataURL()}`, 512, 512).then((canvas2) => {
            setCroppedImageAsset(canvas2)
          }))
        });


        //console.log(croppedImageAsset)
        setLoading(false)
      } else {
        setLoading(false);
        setWrongImageType(true);
      }
    }
  };


  // const compressedFile = new Compressor(selectedFile, {
  //   quality: 0.1,
  //   success: (compressedResult) => {
  //     console.log(compressedResult)
  //     return compressedResult
  //   },
  // });

  // uploading asset to sanity
  // if (selectedFile.type === 'image/png' || selectedFile.type === 'image/svg' || selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/gif' || selectedFile.type === 'image/tiff') {
  //   setImageAsset('uploaded')
  //   setCroppedImageAsset()  
  //   setWrongImageType(false);
  //   setLoading(true);
  //   crop(selectedBase64, 1).then((canvas) => {
  //           canvas.crossOrigin = 'Anonymous';
  //           (resizedataURL(`${canvas.toDataURL()}`, 512, 512).then((canvas2) => {

  //             setCroppedImageAsset(canvas2)
  //           }))
  //         });


  //   console.log(croppedImageAsset)
  //   setLoading(false)
  // client.assets
  //   .upload('image', selectedFile, { contentType: selectedFile.type, filename: selectedFile.name })
  //   .then((document) => {
  //     setImageAsset(document);
  //     setLoading(false);
  //     crop(selectedBase64, 1).then((canvas) => {
  //       canvas.crossOrigin = 'Anonymous';
  //       //console.log(canvas)
  //       //console.log(canvas.toDataURL())
  //       //console.log(resizedataURL(`${canvas.toDataURL()}`, 512, 512))
  //       // setImageAsset(canvas.toDataURL());
  //       (resizedataURL(`${canvas.toDataURL()}`, 512, 512).then((canvas2) => {
  //         setCroppedImageAsset(canvas2)
  //       }))
  //     });

  //   })
  //   .catch((error) => {
  //     console.log('Upload failed:', error.message);
  //   });
  //   } else {
  //     setLoading(false);
  //     setWrongImageType(true);
  //   }


  // };


  const fetchData = (e) => {
    setFetching(true)
    setStatusMessage('')
    const fileToUse = toDataURL(croppedImageAsset)
      .then(dataUrl => {
        var fileData = dataURLtoFile(dataUrl, "imageName.jpg");
        //console.log(fileData)
        //console.log(prompt)
        formData.set('init_image', fileData);
        formData.append("init_image_mode", "IMAGE_STRENGTH");
        formData.append("image_strength", 0.60);
        formData.append("text_prompts[0][text]", `${prompt}`);
        formData.append("text_prompts[0][weight]", 1.7)
        formData.append("text_prompts[1][text]", "human person");
        formData.append("text_prompts[1][weight]", -1)
        formData.append("text_prompts[2][text]", "artifact");
        formData.append("text_prompts[2][weight]", -1)
        formData.append("text_prompts[3][text]", "words");
        formData.append("text_prompts[3][weight]", -1)
        formData.append("cfg_scale", 10);
        formData.append("clip_guidance_preset", "SLOWEST");
        formData.append("height", 512);
        formData.append("width", 512);
        formData.append("samples", 1);
        formData.append("steps", 50);

        fetch(process.env.REACT_APP_DREAMSTUDIO_IMG_IMG_ENDPOINT, {
          method: 'POST',
          headers: {
            "Accept": 'image/png',
            "Authorization": process.env.REACT_APP_DREAMSTUDIO_API_KEY,
          },
          body: formData
        })
          .then((response) => {
            return response.blob();
          })
          .then((blob) => {
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
              var base64data = reader.result;
              setStatusMessage(base64data)
              //console.log(link)
              setFetching(false);
              setTimeout(function () { }, 1000);
              myRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
            }
          })
          .catch(() => {
            setStatusMessage("ERROR");
            setFetching(false);
          })
      })
  }


  const savePin = () => {
    setSaveLoading(true)
    //console.log(saveLoading)

    const dataURLtoFile = (dataurl, filename) => {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }
    //Upload file to Sanity CDN
    const uploadImage = () => {
      if (transferfile.type === 'image/png' || transferfile.type === 'image/svg' || transferfile.type === 'image/jpeg' || transferfile.type === 'image/gif' || transferfile.type === 'image/tiff') {
        //console.log(transferfile)
        client.assets
          .upload('image', transferfile, { contentType: transferfile.type, filename: transferfile.name })
          .then((document) => {
            //console.log(document)

            const doc = {
              _type: 'pin',
              title,
              about,
              destination,
              source,
              image: {
                _type: 'image',
                asset: {
                  _type: 'reference',
                  _ref: document?._id,
                },
              },
              userId: user._id,
              postedBy: {
                _type: 'postedBy',
                _ref: user._id,
              },
              category,
            };

            //console.log(doc)

            client.create(doc).then(() => {
              setSaveLoading(false)
              //console.log(saveLoading)
              navigate('/user-profile/'+`${user._id}`);
            })
          })
          .catch((error) => {
            //console.log('Upload failed:', error.message);
          });
      } else {
        //console.log('not done')
      }
    };

    const transferfile = dataURLtoFile(statusMessage, 'hello.jpeg')
    uploadImage();
  };


  return (
    <div className="flex flex-col justify-center items-center mt-5 lg:h-4/5">
      {fields && (
        <p className="text-red-500 mb-5 text-xl transition-all duration-150 ease-in ">Please add all fields.</p>
      )}
      <div className=" flex lg:flex-row flex-col bg-white lg:p-5 p-3 lg:w-4/5  w-full">
        <div className="bg-secondaryColor p-3 w-full">
          <div className=" flex justify-center items-center flex-col border-2 border-dotted border-gray-300 p-3 w-full h-full sm:h-full">
            {loading && (
              <Spinner />
            )}
            {
              wrongImageType && (
                <p>Wrong file type.</p>
              )
            }
            {!imageAsset ? (
              // eslint-disable-next-line jsx-a11y/label-has-associated-control
              <label>
                <div className="flex flex-col items-center justify-center h-full">
                  <div className="flex flex-col justify-center items-center">
                    <p className="font-bold text-2xl mt-32">
                      <AiOutlineCloudUpload />
                    </p>
                    <p className="text-lg">Step 1: Click to upload</p>
                  </div>

                  <p className="mt-32 text-gray-400">
                    Snap or upload a picture of your space of interest. Must be in JPG, JPEG, SVG, PNG, GIF or TIFF. Aspect ratio must be 1:1. Try to make sure that the room is not completely empty -- the AI needs some cues to work!
                  </p>
                </div>
                <input
                  type="file"
                  name="upload-image"
                  onChange={uploadImage}
                  className="w-0 h-0"
                />
              </label>
            ) : (
              <div className="relative h-full">
                <img
                  src={croppedImageAsset}
                  alt="uploaded-pic"
                  className="object-scale-down object-left-top h-full"
                  // onError={({ currentTarget }) => {
                  //   currentTarget.onerror = null; // prevents looping
                  //   currentTarget.src="https://raw.githubusercontent.com/tanw89/tanw89.github.io/master/loading.jpg";
                  // }}
                />
                <button
                  type="button"
                  className="absolute bottom-3 right-3 p-3 rounded-full bg-white text-xl cursor-pointer outline-none hover:shadow-md transition-all duration-500 ease-in-out"
                  onClick={deletePic}
                >
                  <MdDelete />
                </button>
              </div>
            )}


            {imageAsset ? (<div className='w-full'>
              <div>
                <p className="mb-2 mt-4 font-semibold">Step 2: Select Room</p>
                <select
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                  className="outline-none w-full text-base border-b-2 border-gray-200 p-2 rounded-md cursor-pointer"
                >
                  {/* <option value="others" className="sm:text-bg bg-white">Select Category</option> */}
                  {categories.map((item) => (
                    <option className="text-base border-0 outline-none capitalize bg-white text-black " key={item.name} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <p className="mb-2 mt-4 font-semibold">Step 3: Select Style</p>
                <select
                  onChange={(e) => {
                    setStyle(e.target.value);
                  }}
                  className="outline-none w-full text-base border-b-2 border-gray-200 p-2 rounded-md cursor-pointer"
                >
                  {/* <option value="others" className="sm:text-bg bg-white">Select Category</option> */}
                  {styles.map((item) => (
                    <option className="text-base border-0 outline-none capitalize bg-white text-black " key={item.name} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex-col text-center mt-5">
                <button className="bg-red-500 text-white font-bold p-2 rounded-full mb-4 w-40 outline-none" onClick={fetchData}>Reimagine</button>
              </div>

            </div>) : (<div></div>)}

          </div>



        </div>


        {/* rightmost box */}
        <div className="bg-primaryColor p-3 w-full">
          <div className=" flex justify-center items-center flex-col border-2 border-dotted border-gray-300 p-3 w-full h-full">
            {
              wrongImageType && (
                <p>It&apos;s wrong file type.</p>
              )
            }
            {!statusMessage ?

              <>
                {!fetching ? (
                  // eslint-disable-next-line jsx-a11y/label-has-associated-control
                  <div className="flex flex-col items-center justify-center h-full">
                    <div className="flex flex-col justify-center items-center">
                      <p className="text-lg">Results will appear here</p>
                    </div>
                  </div>
                ) : (
                  <div className="text-center">
                    <Spinner />
                    <br></br>
                    <CountUp start={0} end={100} duration={27} delay={0}>
                      {({ countUpRef }) => (
                        <div >
                          Loading about <b><span ref={countUpRef} /> %</b>
                        </div>
                      )}
                    </CountUp>
                  </div>
                )}
              </>



              : (
                <div className="relative h-full">
                  <img
                    src={`${statusMessage}`}
                    alt="uploaded-pic"
                    className="h-full w-full"
                  />

                </div>
              )}


            {statusMessage ?
              (<div className='w-full h-full'>
                <div className="flex-col text-center mt-5">
                  <p className="mb-2 mt-4 font-semibold">Like this image?</p>
                  <button className="bg-green-500 text-white font-bold p-2 rounded-full mb-4 w-40 outline-none" onClick={savePin}>{!saveLoading ? "Save" : "Loading..."} </button>
                </div>

              </div>) : (<div></div>)}
          </div>



        </div>

        <div ref={myRef}></div>
      </div>
    </div>
  );
};

export default CreatePin;