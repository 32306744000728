// import React from 'react'
// import ReactDOM from 'react-dom'
// import {BrowserRouter as Router} from 'react-router-dom'
// import './index.css'
// import App from './App'

// ReactDOM.render(
//     <Router>
//         <App /> 
//     </Router>,
// document.getElementById('root'))

import { createRoot } from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom'
import './index.css'
import App from './App'
import ReactGA from "react-ga4"

ReactGA.initialize(process.env.REACT_APP_GAKEY);

ReactGA.send({ hitType: "pageview", page: "/", title: "Home" });
ReactGA.send({ hitType: "pageview", page: "/create-with-ai", title: "Create" });
ReactGA.send({ hitType: "pageview", page: "/reimagine", title: "Reimagine" });

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <Router>
        <App /> 
    </Router>
);